import {useIntl} from 'react-intl'
import {PageTitle} from 'src/_config/layout/core';
import { DashboardCompleted } from './DashboardCompleted';
import { DashboardUncompleted } from './DashboardUncompleted';
import {useAuth} from 'src/auth/core/Auth';


const DashboardWrapper = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();
  const completed = currentUser?.status?.all_set || false;
  const admin = currentUser?.admin || 0;

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      { completed || admin ?
        <DashboardCompleted />
      :
        <DashboardUncompleted />
      }
    </>
  )
}

export {DashboardWrapper}
