/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'src/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth();
  const image = currentUser?.afbeelding ? currentUser?.afbeelding : toAbsoluteUrl('/media/avatars/blank.png');

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Gebruiker' src={ image } />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <div className='fw-bold text-muted d-flex align-items-center fs-5'>
              {currentUser?.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/account/profile'} className='menu-link px-5'>
          Mijn profiel
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/account/changepassword'} className='menu-link px-5'>
          Wachtwoord wijzigen
        </Link>
      </div> 

      <div className='menu-item px-5'>
        <Link to={'account/changemailaddress'} className='menu-link px-5'>
          Mailadres wijzigen
        </Link>
      </div>  

      <div className='menu-item px-5'>
        <Link to={'account/changephonenumber'} className='menu-link px-5'>
          Telefoonnummer wijzigen
        </Link>
      </div>  

      <div className='menu-item px-5 my-1'>
        <Link to='/account' className='menu-link px-5'>
          Mijn account
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/account/cancelaccount' className='menu-link px-5'>
          Mijn account opzeggen
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Uitloggen
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
